import * as React from 'react';
import {
  RecoilRoot
} from 'recoil';
import CmpTodos from './CmpTodos';

const CmpWrapperTodos = () => {
	return <RecoilRoot>
		<CmpTodos></CmpTodos>
	</RecoilRoot>;
}

export default CmpWrapperTodos;