import { selector } from 'recoil';
import { stateTodos, stateStatusTodos } from './atoms';

const selectorTodosFiltered = selector({
	key: 'selectorTodosFiltered',
	get: ({get}) => {
		const todosFiltered = get(stateStatusTodos);
		const todosRaw = get(stateTodos);
		
		switch(todosFiltered) {
			case 'Show Accomplished':
				return todosRaw.filter((prev: any) => {
					return prev.isAccomplished;
				})
			case 'Show Unaccomplished':
				return todosRaw.filter((prev: any) => {
					return !prev.isAccomplished;
				})
			default:
				return todosRaw;
		}
	}
})

export { selectorTodosFiltered };