import * as React from 'react';
import { useRecoilState } from 'recoil';
import { stateStatusTodos } from '../../recoil/atoms';
import type { TStatusTodo } from '../../interfaces';

const CmpFilterTodo : React.FC = () => {
	const [selectedFilter, setSelectedFilter] = useRecoilState(stateStatusTodos);
	return <select value={selectedFilter} onChange={(e) => {
			if(e && e.target && e.target.value) {
				setSelectedFilter(e.target.value as TStatusTodo);
			}
		}}>
		<option value="Show All">Show All</option>
		<option value="Show Accomplished">Show Accomplished</option>
		<option value="Show Unaccomplished">Show Unaccomplished</option>
	</select>;
}

export default CmpFilterTodo;