import * as React from 'react';
import {useSetRecoilState} from 'recoil';
import {stateTodos} from '../../recoil';
import {getId} from '../../utils/index';

interface IPropsCmpAddTodo {
	
}

const CmpAddTodo: React.FC<IPropsCmpAddTodo> = ({}) => {
	const [enteredTitleTodo, setEnteredTitleTodo] = React.useState<string>('');
	const [enteredDescriptionTodo, setEnteredDescriptionTodo] = React.useState<string>('');
	const setTodos = useSetRecoilState(stateTodos);
	return <div>
		<div style={{display:'flex', justifyContent: 'space-between'}}>
			<input style={{flex: '1'}} type="text" value={enteredTitleTodo} onChange={(e) => {
					if(e && e.target && e.target.value) {
						setEnteredTitleTodo(e.target.value);
					}
				}} />
			<input style={{flex: '1'}} type="text" value={enteredDescriptionTodo} onChange={(e) => {
					if(e && e.target && e.target.value) {
						setEnteredDescriptionTodo(e.target.value);
					}
				}} />
		</div>
		<div>
			<button style={{padding: '5px'}} type="button" onClick={() => {
					setTodos((prev) => {
						return prev.concat({
							id: getId(),
							isAccomplished: false,
							title: enteredTitleTodo,
							description: enteredDescriptionTodo
						})
					});
					setEnteredTitleTodo('');
					setEnteredDescriptionTodo('');
				}}>Add Todo</button>
		</div>
		<div>
		</div>
	</div>;
}

export default CmpAddTodo;