import * as React from 'react';

interface IPropsCmpHeader {
	selectedStateMode: string;
    setSelectedStateMode: React.Dispatch<React.SetStateAction<string>>;
}

const CmpHeader: React.FC<IPropsCmpHeader> = ({selectedStateMode, setSelectedStateMode}) => {
	return <header style={{ display: 'inline' }}>
		<div style={{ display : 'flex', justifyContent: 'space-between' }}>
			<button type="button" style={{ borderTop: '1px solid', borderBottom: '0', borderLeft: '1px solid', borderRight: '0', padding: '5px', flex: '1', backgroundColor: selectedStateMode === 'recoil' ? 'black' : 'white', color: selectedStateMode === 'recoil' ? 'white' : 'black' }} onClick={() => {setSelectedStateMode('recoil');}}>Recoil</button>
			<button type="button" style={{ borderTop: '1px solid', borderBottom: '0', borderLeft: '1px solid', borderRight: '0', padding: '5px', flex: '1', backgroundColor: selectedStateMode === 'zustand' ? 'black' : 'white', color: selectedStateMode === 'zustand' ? 'white' : 'black' }} onClick={() => {setSelectedStateMode('zustand');}}>Zustand</button>
			<button type="button" style={{ borderTop: '1px solid', borderBottom: '0', borderLeft: '1px solid', borderRight: '0', padding: '5px', flex: '1', backgroundColor: selectedStateMode === 'jotai' ? 'black' : 'white', color: selectedStateMode === 'jotai' ? 'white' : 'black' }} onClick={() => {setSelectedStateMode('jotai');}}>Jotai</button>
			<button type="button" style={{ borderTop: '1px solid', borderBottom: '0', borderLeft: '1px solid', borderRight: '0', padding: '5px', flex: '1', backgroundColor: selectedStateMode === 'mob-x' ? 'black' : 'white', color: selectedStateMode === 'mob-x' ? 'white' : 'black' }} onClick={() => {setSelectedStateMode('mob-x');}}>Mob-X</button>
			<button type="button" style={{ borderTop: '1px solid', borderBottom: '0', borderLeft: '1px solid', borderRight: '1px solid', padding: '5px', flex: '1', backgroundColor: selectedStateMode === 'redux' ? 'black' : 'white', color: selectedStateMode === 'redux' ? 'white' : 'black' }} onClick={() => {setSelectedStateMode('redux');}}>Redux</button>
		</div>
	</header>;
}

export default CmpHeader;