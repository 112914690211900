import * as React from 'react';
import {default as CmpContainerTodosRecoil} from '../components/recoil/CmpWrapperTodos';
import {default as CmpContainerTodosZustand} from '../components/zustand/CmpTodos';
import CmpListTodoMobX from '../components/mob-x/CmpListTodoMobX';

interface IPropsPageViewTodos {
	selectedStateMode: string;
}

const PageViewTodos : React.FC<IPropsPageViewTodos> = ({selectedStateMode}) => {
	return <>
		{{
			'recoil': <CmpContainerTodosRecoil></CmpContainerTodosRecoil>,
			'zustand': <CmpContainerTodosZustand></CmpContainerTodosZustand>,
			'mob-x': <CmpListTodoMobX></CmpListTodoMobX>
		}[selectedStateMode]}
	</>;
}

export default PageViewTodos;