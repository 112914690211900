import { atom } from 'recoil';
import { ITodo } from '../interfaces';
import type { TStatusTodo } from '../interfaces';

export const stateTodos = atom<ITodo[]>({
	key: 'stateTodos',
	default: []
});

export const stateStatusTodos = atom<TStatusTodo>({
	key: 'stateStatusTodos',
	default: 'Show All'
})