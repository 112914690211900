import { create } from 'zustand';
import { getId } from '../utils';

const storeTodos = (set: any) => {
	/** Maybe it could be used later...... **/
	// const updateTodo = (_id: any, _titleUpdated: any, _descriptionUpdated: any) => {
	// 	set((_state: any) => {
	// 		return {
	// 			todos: _state.todos.map((_todo: any) => {
	// 				if (_todo.id === _id) {
	// 					_todo.title = _titleUpdated;
	// 				}
	// 				return _todo;
	// 			})
	// 		};
	// 	})
	// };
	return {
		todos: [],
		addTodo: (_title: any, _description: any) => {
			const newTodo = {
				id: getId(),
				title: _title,
				description: _description,
				isAccomplished: false
			};
			set((_state: any) => {
				return {
					todos: [..._state.todos, newTodo]
				};
			});
		},
		removeTodo: (_id: any) => {
			set((_state: any) => {
				return {
					todos: _state.todos.filter((_todo: any) => {
						return _todo.id !== _id;
					})
				};
			});
		},
		accomplishTodo: (_id: any) => {
			set((_state: any) => {
				return {
					todos: _state.todos.map((_todo: any) => {
						if(_todo.id === _id) {
							return {
								..._todo,
								isAccomplished: !_todo.isAccomplished
							};
						}
						return _todo;
					})
				};
			});
		}
	};
};

export const useStoreTodos = create(storeTodos);