import * as React from 'react';
import { useStoreTodos } from '../../zustand/store';

const CmpAddTodo : React.FC = () => {
	const addTodo = useStoreTodos((_state: any) => {
		return _state.addTodo;
	});
	const [enteredTitleTodo, setEnteredTitleTodo] = React.useState<string>('');
	const [enteredDescriptionTodo, setEnteredDescriptionTodo] = React.useState<string>('');
	return <div>
		<div style={{display:'flex', justifyContent: 'space-between'}}>
			<input style={{flex: '1'}} type="text" value={enteredTitleTodo} onChange={(e) => {
					if(e && e.target && e.target.value) {
						setEnteredTitleTodo(e.target.value);
					}
				}} />
			<input style={{flex: '1'}} type="text" value={enteredDescriptionTodo} onChange={(e) => {
					if(e && e.target && e.target.value) {
						setEnteredDescriptionTodo(e.target.value);
					}
				}} />
		</div>
		<div>
			<button style={{padding: '5px'}} type="button" onClick={() => {
					addTodo(enteredTitleTodo, enteredDescriptionTodo);
					setEnteredTitleTodo('');
					setEnteredDescriptionTodo('');
				}}>Add Todo</button>
		</div>
		<div>
		</div>
	</div>;
}

export default CmpAddTodo;