import * as React from 'react';
import type { TStatusTodo } from '../../interfaces';
import { useStoreTodos } from '../../zustand/store';
import CmpAddTodo from './CmpAddTodo';
import CmpFilterTodo from './CmpFilterTodo';

const CmpTodos = () => {
	const [selectedFilter, setSelectedFilter] = React.useState<TStatusTodo>('Show All');
	const todos = useStoreTodos((_state: any) => {
		switch(selectedFilter) {
			case 'Show All':
				return _state.todos;
			case 'Show Accomplished':
				return _state.todos.filter((_todo: any) => {
					return _todo.isAccomplished;
				});
			case 'Show Unaccomplished':
				return _state.todos.filter((_todo: any) => {
					return !(_todo.isAccomplished);
				});
			default:
				return _state.todos;
		}
	});
	const accomplishTodo = useStoreTodos((_state) => {
		return _state.accomplishTodo;
	});
	const removeTodo = useStoreTodos((_state) => {
		return _state.removeTodo;
	});
	return <div className="container">
		<CmpAddTodo />
		<CmpFilterTodo selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
		<div>
			{todos.map((_todo: any, _index: any) => {
				return <div key={_index} style={{cursor: 'pointer'}} onClick={() => {
					accomplishTodo(_todo.id);
				}}>
				<span>{_todo.isAccomplished ? <del>{_todo.title}</del> : <>{_todo.title}</>}</span><br/>
				<span>{_todo.isAccomplished ? <del>{_todo.description}</del> : <>{_todo.description}</>}</span>
				<span><button type="button" onClick={() => {
					removeTodo(_todo.id);
				}}>Remove Todo</button></span>
			</div>;
			})}
		</div>
	</div>
}

export default CmpTodos;