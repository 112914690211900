import * as React from 'react';
import { selectorTodosFiltered } from '../../recoil/selectors';
import { stateTodos } from '../../recoil';
import {
  useRecoilValue, useSetRecoilState
} from 'recoil';
import CmpAddTodo from './CmpAddTodo';
import CmpFilterTodo from './CmpFilterTodo';

const CmpTodos: React.FC = () => {
	const todos = useRecoilValue(selectorTodosFiltered);
	const setTodos = useSetRecoilState(stateTodos);
	return <div className="container">
		<CmpAddTodo />
		<CmpFilterTodo />
		<div>
			{todos.map((_todo: any, _index: number) => {
				return <div key={_index} style={{cursor: 'pointer'}} onClick={() => {
						setTodos((prev: any) => {
							return prev.map((_todo: any) => {
								return _todo.id === _index ? ({ ..._todo, isAccomplished: !_todo.isAccomplished }) : _todo;
							})
						})
					}}>
					<span>{_todo.isAccomplished ? <del>{_todo.title}</del> : <>{_todo.title}</>}</span><br/>
					<span>{_todo.isAccomplished ? <del>{_todo.description}</del> : <>{_todo.description}</>}</span>
					<span><button type="button" onClick={() => {
						setTodos((prev: any) => { return prev.filter((todo: any) => todo.id !== _todo.id); })
					}}>Remove Todo</button></span>
				</div>;
			})}
		</div>
	</div>;
}

export default CmpTodos;