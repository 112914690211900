import * as React from 'react';
import type { TStatusTodo } from '../../interfaces';

interface IPropsCmpFilterTodo {
	selectedFilter: TStatusTodo;
	setSelectedFilter: React.Dispatch<React.SetStateAction<TStatusTodo>>;
}

const CmpFilterTodo: React.FC<IPropsCmpFilterTodo> = ({ selectedFilter, setSelectedFilter }) => {
	return <select value={selectedFilter} onChange={(e) => {
			if(e && e.target && e.target.value) {
				setSelectedFilter(e.target.value as TStatusTodo);
			}
		}}>
		<option value="Show All">Show All</option>
		<option value="Show Accomplished">Show Accomplished</option>
		<option value="Show Unaccomplished">Show Unaccomplished</option>
	</select>;
}

export default CmpFilterTodo;