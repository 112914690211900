import * as React from 'react';
import CmpHeader from './components/CmpHeader';
import PageViewTodos from './pages/PageViewTodos';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const App: React.FC = () => {
	const [selectedStateMode, setSelectedStateMode] = React.useState<string>('recoil');
	return <>
		<CmpHeader selectedStateMode={selectedStateMode} setSelectedStateMode={setSelectedStateMode}></CmpHeader>
		<main style={{ padding: '10px', border: '1px solid' }}>
			<Router>
				<Routes>
					<Route path="/" element={<PageViewTodos selectedStateMode={selectedStateMode} />} />
				</Routes>
			</Router>
		</main>
		<footer style={{ padding: '10px', borderTop: '0', borderBottom: '1px', borderLeft: '1px', borderRight: '1px' }}>This is just a practice, you can use any of these codes!</footer>
	</>
}
export default App;
